import React from 'react'

export default function Hero() {
  return (
    <div id="home">
      <div id="name">
        <div id="first"> Winston Meikle</div>
        <div id="second">Software Engineer</div>

      </div> 
    
  </div>
  )
}
